<template>
  <el-main>
    <el-form :model="formData" status-icon :rules="rules" ref="ruleForm" label-width="120px">
      <el-form-item label="图册名称">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="图册排序">
        <el-input v-model="formData.rank"></el-input>
      </el-form-item>
      <el-form-item label="图册图片">
        <div class="drag_all_box">
          <vuedraggable
            v-model="choose_atlas"
            v-bind="{
              animation: 200,
              group: 'description',
              disabled: false,
              ghostClass: 'ghost',
              scroll: true,
            }"
            @end="dragEnd"
          >
            <transition-group>
              <div :data-text="`第${i}张`" class="drag_box" v-for="(img, i) in choose_atlas" :key="i">
                <div class="img">
                  <el-image style="width: 100%; height: 100%" :src="img.path" :preview-src-list="[img.path]"></el-image>
                  <i class="el-icon-error delete" @click="removeThis(i)"></i>
                </div>
              </div>
            </transition-group>
          </vuedraggable>
          <div class="upload_all_box">
            <div class="upload_btn">
              <i class="el-icon-plus"></i>
              <input @input="getSourceFile($event)" class="file_input" multiple type="file" />
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <Preservation @preservation="submit"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import vuedraggable from 'vuedraggable';
import { dealAtals, fileChangeImage, getfilesize, getSourceWidthHeight } from '@/util/util';
export default {
  components: {
    Preservation,
    vuedraggable,
  },
  data() {
    return {
      formData: {
        title: '',
        picture: [],
        draw_images: [],
        rank: '',
      },
      choose_atlas: [],
      rules: {},
    };
  },
  methods: {
    dragEnd() {},
    getSourceFile($event) {
      let files = $event.target.files;
      let type_list = ['.png', '.jpg', '.jpeg', '.gif'];
      let max_size = 1024 * 1024 * 10;
      let that = this;
      (async function () {
        for (const key in files) {
          if (!isNaN(Number(key))) {
            let str = files[key].name.lastIndexOf('.');
            let lastStr = files[key].name.substr(str).toLowerCase();
            let res = type_list.findIndex(item1 => item1 == lastStr);
            if (res == -1) {
              this.$message({
                message: `${files[key].name}不符合上传类型，已从上传队列删除`,
                type: 'error',
                customClass: 'mzindex',
              });
              continue;
            }
            let base64 = await fileChangeImage(files[key]);
            let { width, height } = await getSourceWidthHeight(base64);
            that.choose_atlas.push({
              file: files[key],
              name: files[key].name,
              size: getfilesize(files[key].size),
              width,
              height,
              path: base64,
            });
          }
        }
      })();
    },
    async submit() {
      if (this.formData.title === '') {
        this.$message.warning('请填写画册标题');
        return;
      }
      if (this.formData.rank === '') {
        this.$message.warning('请填写画册排序');
        return;
      }
      if (!this.choose_atlas.length) {
        this.$message.warning('请填写画册排序');
        return;
      }
      let pc_draw_images = [];
      for (let i = 0; i < this.choose_atlas.length; i++) {
        let from = new FormData();
        from.append('files', this.choose_atlas[i].file);
        from.append('wide', this.choose_atlas[i].width);
        from.append('height', this.choose_atlas[i].height);
        let { result } = await this.$axios.uploadFile(this.$api.user.addFiles, from);
        pc_draw_images.push(result.path);
        let data = this.choose_atlas[i];
        let f_img = await dealAtals(data, 1);
        this.formData.draw_images.push(f_img);
        let b_img = await dealAtals(data, 2);
        this.formData.draw_images.push(b_img);
      }
      //处理封面和最后一张
      this.formData.draw_images.push(this.formData.draw_images.splice(0, 1)[0]);
      let obj = {
        title: this.formData.title,
        rank: this.formData.rank,
        picture: this.formData.draw_images[0],
        draw_images: this.formData.draw_images,
        pc_draw_images,
      };
      this.$axios.post(this.$api.electronicAtlas.addAtlas, obj).then(res => {
        if (res.code == 0) {
          this.$message.success('创建成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  .el-input {
    width: 400px;
  }
}
.drag_all_box {
  width: 700px;
  .drag_box {
    width: 280px;
    height: 200px;
    margin-bottom: 14px;
    img {
      width: 100%;
      height: 100%;
    }
    position: relative;
    i {
      font-size: 36px;
      position: absolute;
      top: -10px;
      right: -10px;
    }
    &::before {
      display: block;
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      text-align: center;
      background-color: #fff;
      color: #333;
    }
  }
}

.upload_all_box {
  .upload_btn {
    width: 280px;
    height: 200px;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 5px;
    cursor: pointer;
    vertical-align: top;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    i {
      font-size: 28px;
      color: #8c939d;
    }
    .file_input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}
</style>
